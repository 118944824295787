import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { styled } from '@mui/material/styles';
import theme from '@/mui/theme';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@/components/icons/CloseIcon';

export const CustomDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    maxWidth: '730px',
    background: '#CCCFD4',
    borderRadius: '22px',
  },
  '& .MuiDialogContent-root': {
    padding: '20px 65px 40px',
    border: 0,
    color: '#000',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    'p': {
      margin: '0 0 24px',
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-paper': {
      margin: '25px',
    },
    '& .MuiDialogContent-root': {
      padding: '15px 25px 15px',
    },
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  margin: 0,
  padding: '50px 65px 20px',
  color: '#182641',
  fontWeight: 'bold',
  fontSize: '34px',
  lineHeight: '38px',
  letterSpacing: '-0.23px',
  '& .MuiButtonBase-root': {
    top: '22px',
    right: '22px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '24px 50px 10px 25px',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '24px',
  },
});

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const CustomDialogTitle = (props: DialogTitleProps) => {
  const { t } = useTranslation('common');
  const { children, onClose, ...other } = props;

  return (
    <StyledDialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label={t('dialog-close', {}, {default: 'Close'})}
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon width={12} height={12} />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
};

export const CustomDialogContent = DialogContent;
