import React from 'react';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import CmsLinks from '../ui/CmsLinks';
import styled from '@mui/system/styled';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import theme from '@/mui/theme';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import { CustomDialog, CustomDialogTitle, CustomDialogContent } from '@/components/ui/CustomDialog';
import { sanitize } from '@/utils/miscellaneous';
import { getIconComponentByName } from '@/utils/icons-map';
import { WPFullBanner, WPFullBannerBackgroundColor, WPFullBannerIcon, WPFullBannerTextPosition } from '@/data/wp/operations/queries/fragments/fascia-full-banner';

interface FullBannerProps extends WPFullBanner {}
interface FullBannerIconProps extends WPFullBannerIcon {}

const StyledOcchiello = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    color: '#dedede',
    textShadow: '0 0 10px rgb(0, 0, 0)',
  },
  color: '#000000',
  fontSize: '22px',
  lineHeight: '28px',
  letterSpacing: '-0.31px',
  '.textColorWhite &': {
    color: '#ffffff'
  },
  '.center &': {
    textAlign: 'center'
  }
}) as typeof Typography;

const StyledTitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    fontSize: '51px',
    lineHeight: '54px',
    letterSpacing: '0',
    color: '#ffffff',
    textShadow: '0 0 10px rgb(0, 0, 0)',
  },
  color: '#000000',
  fontSize: '34px',
  fontWeight: '800',
  letterSpacing: '-0.23px',
  lineHeight: '39px',
  '&.smallTitle': {
    fontSize: '34px',
    fontWeight: '800',
    letterSpacing: '-0.23px',
    lineHeight: '39px',
  },
  '.textColorWhite &': {
    color: '#ffffff'
  },
  '.center &': {
    textAlign: 'center',
    marginBottom: '20px'
  }
}) as typeof Typography;

const StyledDesc = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    letterSpacing: '0.1px',
    color: '#dedede',
    textShadow: '0 0 10px rgb(0, 0, 0)',
  },
  color: '#000000',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0',
  'ul': {
    paddingLeft: '18px',
    '> li': {
      fontWeight: 'bold',
      '&::marker': {
        color: '#ff5c04',
      }
    }
  },
  '.textColorWhite &': {
    color: '#ffffff'
  },
  '.center &': {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      letterSpacing: '-0.45px',
      lineHeight: '28px',
      fontWeight: '400',
      padding: '0 30px',
    }
  }
}) as typeof Typography;

const StyledSubtitle = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    letterSpacing: '0.1px',
    color: '#dedede',
    textShadow: '0 0 10px rgb(0, 0, 0)',
  },
  color: '#000000',
  fontSize: '22px',
  lineHeight: '28px',
  letterSpacing: '-0.31px',
  '.textColorWhite &': {
    color: '#ffffff'
  },
  '.center &': {
    textAlign: 'center'
  }
}) as typeof Typography;

const StyledGridContainer = styled(Grid)({
  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    bottom: '63px',
    '.center &': {
      bottom: '100px'
    }
  },
  position: 'relative',
  bottom: 'unset',
  '&.backgroundImageCover': {
    position: 'relative',
    bottom: 'unset',
    paddingTop: '101px',
    paddingBottom: '90px',
  },
  '.center &': {
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0'
    }
  }
}) as typeof Grid;

const StyledIconButton = styled(Button)({
  display: 'flex',
  textAlign: 'left',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: '#000',
  padding: '10px 30px',
  marginBottom: '18px',
  borderRadius: '22px',
  backgroundColor: '#CCCFD4',
  '&:hover': {
    backgroundColor: '#e7e7e7',
  },
  'svg': {
    flexShrink: '0',
  },
});

const StyledIconTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: '25px',
  letterSpacing: '0',
  marginBottom: '0',
}) as typeof Typography;

const StyledIconDesc = styled(Typography)({
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: '20px',
  letterSpacing: '0',
}) as typeof Typography;

function getColorHex(backgroundColorMobile?: WPFullBannerBackgroundColor) {
  switch (backgroundColorMobile) {
    case 'transparent':
      return "#00000000";
    case 'blue':
      return "#111a2f";
    case 'black':
      return "#000000";
    case 'lightbrown':
      return "#6E6458";
    case 'green':
      return "#25350A";
    case 'gray':
    default:
      return "#f1f1f1";
  }
}

const FullBannerIconButton: React.FC<FullBannerIconProps> = ({ icon, title, desc, detailsTitle, detailsDesc }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <StyledIconButton onClick={handleClickOpen} disableRipple={true}>
      {icon && getIconComponentByName(icon)}
      <Box sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '25px' }}>
        <StyledIconTitle>
          {title}
        </StyledIconTitle>
        <StyledIconDesc>
          {desc}
        </StyledIconDesc>
      </Box>
    </StyledIconButton>
    {(detailsTitle || detailsDesc) &&
      <CustomDialog onClose={handleClose} open={open} aria-labelledby="customized-dialog-title">
        <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {detailsTitle}
        </CustomDialogTitle>
        <CustomDialogContent dividers>
          {detailsDesc &&
            <div dangerouslySetInnerHTML={{__html: sanitize(detailsDesc)}} />
          }
        </CustomDialogContent>
      </CustomDialog>
    }
  </>
}

const FullBanner: React.FC<FullBannerProps> = ({ backgroundImage, backgroundImageCover, textPosition = WPFullBannerTextPosition.left, textColorWhite, backgroundColorMobile, smallTitle, occhiello, title, subtitle, desc, links, icons }) => {
  const matches = useMediaQuery('(min-width:600px)');
  const backgroundImageCoverClass = backgroundImageCover ? 'backgroundImageCover' : '';
  const smallTitleClass = smallTitle ? 'smallTitle' : '';
  const textColorWhiteClass = textColorWhite ? 'textColorWhite' : '';

  return (
    <Box className={textColorWhiteClass} sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
      {!backgroundImageCover && backgroundImage &&
        <Box sx={{ position: 'relative', display: 'block', width: '100%', height: matches ? backgroundImage.mediaDetails.height : '414px' }}>
          <Image
            layout="fill"
            src={backgroundImage.mediaItemUrl}
            alt={backgroundImage.altText}
            objectFit="cover"
            objectPosition={!matches? (textPosition === WPFullBannerTextPosition.left ? 'right' : 'left') : ''}
          />
        </Box>
      }
      {backgroundImageCover && backgroundImage &&
        <Image
          layout="fill"
          src={backgroundImage.mediaItemUrl}
          alt={backgroundImage.altText}
          objectFit="cover"
        />
      }
      {textPosition === WPFullBannerTextPosition.right &&
        <Container sx={{ position: 'relative', zIndex: '20', backgroundColor: { sm: 'transparent', xs: getColorHex(backgroundColorMobile) } }}>
          <StyledGridContainer className={backgroundImageCoverClass} container rowSpacing={0}>
            <Grid item sm={6} xs={0}></Grid>
            <Grid item sm={6} xs={12}>
              {occhiello &&
                <StyledOcchiello sx={{ marginBottom: { sm: 1.25, xs: 1.5 }, marginTop: 4, textTransform: 'uppercase' }}>{occhiello}</StyledOcchiello>
              }
              {title &&
                <StyledTitle className={smallTitleClass} mt={occhiello ? 0 : 4} sx={{ mb: { sm: smallTitle ? 2.5 : 5, xs: 3 } }}>{title}</StyledTitle>
              }
            </Grid>
            <Grid item sm={6} xs={0}></Grid>
            <Grid item sm={6} xs={12}>
              {subtitle &&
                <StyledSubtitle mt={0} sx={{ mb: { sm: 5, xs: 3 } }}>{subtitle}</StyledSubtitle>
              }
              {desc &&
                <StyledDesc
                  component="div"
                  sx={{ marginBottom: { sm: '0', xs: icons && icons.length > 0 ? '10px': '63px' } }}
                  dangerouslySetInnerHTML={{__html: sanitize(desc)}}
                />
              }
            </Grid>
            <Grid item sm={6} xs={0}></Grid>
            <Grid item sm={4} xs={12} sx={{  marginTop: '30px', marginBottom: { sm: 0, xs: '30px' } }}>
              {icons && icons.map((singleIcon, index) => <FullBannerIconButton key={index} {...singleIcon}></FullBannerIconButton>)}
            </Grid>
            <Grid item sm={2} xs={0}></Grid>
            <Grid item sm={6} xs={0}></Grid>
            <Grid item sm={6} xs={12}>
              {links && links.length > 0 &&
                <CmsLinks data={links} direction="row" sx={{ marginTop: { sm: 4, xs: 2.625 } }}></CmsLinks>
              }
            </Grid>
          </StyledGridContainer>
        </Container>
      }
      {textPosition === WPFullBannerTextPosition.left &&
        <Container sx={{ position: 'relative', zIndex: '20', backgroundColor: { sm: 'transparent', xs: getColorHex(backgroundColorMobile) } }}>
          <StyledGridContainer className={backgroundImageCoverClass} container rowSpacing={0}>
            <Grid item sm={7} xs={12}>
              {occhiello &&
                <StyledOcchiello sx={{ marginBottom: { sm: 1.25, xs: 1.5 }, marginTop: 4, textTransform: 'uppercase' }}>{occhiello}</StyledOcchiello>
              }
              {title &&
                <StyledTitle className={smallTitleClass} mt={occhiello ? 0 : 4} sx={{ mb: { sm: smallTitle ? 2.5 : 5, xs: 3 } }}>{title}</StyledTitle>
              }
            </Grid>
            <Grid item sm={5} xs={0}></Grid>
            <Grid item sm={5} xs={12}>
              {subtitle &&
                <StyledSubtitle mt={0} sx={{ mb: { sm: 5, xs: 3 } }}>{subtitle}</StyledSubtitle>
              }
              {desc &&
                <StyledDesc
                  component="div"
                  sx={{ marginBottom: { sm: '0', xs: '63px' } }}
                  dangerouslySetInnerHTML={{__html: sanitize(desc)}}
                />
              }
            </Grid>
            <Grid item sm={7} xs={0}></Grid>
            <Grid item sm={7} xs={12}>
              {links && links.length > 0 &&
                <CmsLinks data={links} direction="row" sx={{ marginTop: { sm: 4, xs: 2.625 } }}></CmsLinks>
              }
            </Grid>
          </StyledGridContainer>
        </Container>
      }
      {textPosition === WPFullBannerTextPosition.center &&
        <Container className="center" sx={{ position: 'relative', zIndex: '20', backgroundColor: { sm: 'transparent', xs: getColorHex(backgroundColorMobile) } }}>
          <StyledGridContainer className={backgroundImageCoverClass} container rowSpacing={0}>
            <Grid item sm={3} xs={0}></Grid>
            <Grid item sm={6} xs={12}>
              {occhiello &&
                <StyledOcchiello sx={{ marginBottom: { sm: 1.25, xs: 1.5 }, marginTop: 4, textTransform: 'uppercase' }}>{occhiello}</StyledOcchiello>
              }
              {title &&
                <StyledTitle className={smallTitleClass} mt={occhiello ? 0 : 4} sx={{ mb: { sm: smallTitle ? 2.5 : 5, xs: 3 } }}>{title}</StyledTitle>
              }
            </Grid>
            <Grid item sm={3} xs={0}></Grid>
            <Grid item sm={3} xs={0}></Grid>
            <Grid item sm={6} xs={12}>
              {subtitle &&
                <StyledSubtitle mt={0} sx={{ mb: { sm: 5, xs: 3 } }}>{subtitle}</StyledSubtitle>
              }
              {desc &&
                <StyledDesc
                  component="div"
                  sx={{ marginBottom: { sm: '0', xs: '63px' } }}
                  dangerouslySetInnerHTML={{__html: sanitize(desc)}}
                />
              }
            </Grid>
            <Grid item sm={3} xs={0}></Grid>
          </StyledGridContainer>
        </Container>
      }
    </Box>
  )
}

export default FullBanner;
